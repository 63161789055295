<template>
    <div class="unipet-wrapper">
        <index-navbar />

        <!-- <section class="section1" id="home">
            <span class="slogan animated zoomIn"></span>
            <span class="character nv-1"></span>
            <span class="character nv-3"></span>
            <span class="character nv-4"></span>
            <span class="character nv-5"></span>
            <span class="character nv-2"></span>
            <span class="character nv-6"></span>
            <video src="/images/SplashArt3.mp4" class="videoFullscreen bg-video" autoplay="autoplay" loop muted></video>
        </section> -->
        <section class="section2-2" style="margin-top: 4%;">
            <div class="box-choose">
                <div class="tab-content tab-content-sex">
                    <div class="tab-pane fade show active" id="v-pills-home" role="tabpanel"
                        aria-labelledby="v-pills-home-tab">
                        <img class="boss" src="/images/pets/boss_4.png" />
                        <img class="boss-ui" src="/images/pets/boss_3.png" />
                    </div>

                </div>
            </div>
        </section>
        <section class="section2">  
              <div class="layer-1"></div>
            <span class="title-1"></span>
            <div class="box-choose">
                <a href="#section3" class="btn-join"></a>
                <div class="tab-content tab-content-sex">
                    <div class="tab-pane fade show active" id="v-pills-home" role="tabpanel"
                        aria-labelledby="v-pills-home-tab">
                        <img class="img-fluid male" src="/images/pets/boss_3.png" />
                    </div>

                </div>
            </div>
        </section>
        <section class="section2-3">
            <div class="box-choose">
                <div class="tab-content tab-content-sex">
                    <div class="tab-pane fade show active" id="v-pills-home" role="tabpanel"
                        aria-labelledby="v-pills-home-tab">
                        <img class="boss" src="/images/pets/boss_2.png" />
                        <img class="weapon" src="/images/weapon.png" />
                        <img class="battle-axe" src="/images/battle-axe.png" />
                        <img class="face" src="/images/face.png" />
                    </div>
                    <div class="network-new">
                        <span class="title-3"></span>
                        <div class="row justify-content-center">
                            <div class="col-4">
                                <div class="built-network-item">
                                    <a rel="noreferrer" href="https://solana.com/" target="_blank"><img
                                            src="/images/network/solana.webp" /></a>
                                    <span class="built-in">Solana</span>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="built-network-item">
                                    <a rel="noreferrer" href="https://binance.com/" target="_blank"><img
                                            src="/images/network/binance.png" /></a>
                                    <span class="built-in">Binance Smart Chain</span>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="built-network-item">
                                    <a rel="noreferrer" href="https://ethereum.org/" target="_blank"><img
                                            src="/images/network/ethereum.svg" /></a>
                                    <span class="built-in">Ethereum</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- <button v-on:click="signAndSendTransaction">Reverse Message</button> -->

        <!-- <section class="section3 section3-1" id="section3" style="padding: 80px 0">
             <span class="title-2"></span>
            <div class="box-nhanvat">
                <div class="slider slider-for">
                    <div class="item">
                        <div class="images">
                            <span><img class="img-fluid" src="/images/pets/boss_1.png" /></span>
                        </div>
                        <div class="info">
                            <h5 class="rs">Unipet</h5>
                            <h6 class="rs dess slide-highlight" style="color: #f0cf18">
                                3D RPG GAME as the first application of
                                Generative <br />AI technology
                            </h6>
                            <div style="display: flex; margin-top: 5px">
                                <button class="join-btn" style="background-color: #f0cf18"
                                    onclick="handleNavigateToGGForm()">
                                    Join The Waitlist
                                </button>
                                <button onclick="handleNavigateToVolunteerGGForm()" class="join-btn">
                                    Join As Volunteer
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> -->
        <section class="section3" id="section3">
            <span class="title-2"></span>
            <div class="box-nhanvat">
                <div class="slider slider-for">
                    <div class="item">
                        <div class="info">
                            <h5 class="rs">Dolgigo</h5>
                            <p class="rs dess">
                                Its can use magic forces to shape the flame
                                as it desired fitting the situation. When
                                focused, it can see the future.
                            </p>
                        </div>                       
                        <div class="images">
                            <span><img class="img-fluid" src="/images/heros/hero-1.png" /></span>
                        </div>
                    </div>
                    <div class="item">
                        <div class="info">
                            <h5 class="rs">Dracotara</h5>
                            <p class="rs dess">
                                Its have small spikes all over it tail and
                                it will get bigger during growth. When it
                                reached adulthood, its tail, foot nad hand
                                cant emit powerful flame.
                            </p>
                        </div>
                        <div class="images">
                            <span><img class="img-fluid" src="/images/heros/hero-2.png" /></span>
                        </div>
                    </div>
                    <div class="item">
                        <div class="info">
                            <h5 class="rs">Honnuwari</h5>
                            <p class="rs dess">
                                Have a very ferocious exterior but actually
                                soft inside, the scale outside is very
                                durable protecting it from other
                                beasts.Usually live with pack and shoot
                                water from its mouth to have fun.
                            </p>
                        </div>
                        <div class="images">
                            <span><img class="img-fluid" src="/images/heros/hero-3.png" /></span>
                        </div>
                    </div>
                    <div class="item">
                        <div class="info">
                            <h5 class="rs">Cravatas</h5>
                            <p class="rs dess">
                                Cravatas is very ferocious and battle
                                hungry, it can create huge water vortex to
                                attack any beast or human who dare come near
                                it. Usually live around warm sea water and
                                because of its vicious nature it also know
                                as the sea savage.
                            </p>
                        </div>
                        <div class="images">
                            <span><img class="img-fluid" src="/images/heros/hero-4.png" /></span>
                        </div>
                    </div>
                    <!-- <div class="item">
                        <div class="info">
                            <h5 class="rs">Venornion</h5>
                            <p class="rs dess">
                                It have powerful control over ice, can cast
                                blizzard on an area to attack enemies, with
                                the area of effect so big, no foe can
                                escape.
                            </p>
                        </div>
                        <div class="images">
                            <span><img class="img-fluid" src="/images/pets/boss_5.png" /></span>
                        </div>
                    </div> -->
                </div>
                <div class="slider slider-nav">
                    <div class="thumb-pet">
                        <img class="img-fluid" src="/images/icon-1.png" />
                        <div class="content-pet">
                            <div class="name-pet">
                                <img src="/images/icon-def.png" />
                                <span class="name-content">Dolgigo</span>
                            </div>
                            <div class="img-pet">
                                <img class="img-fluid" src="/images/heros/hero-1.png" />
                            </div>
                        </div>
                    </div>
                    <div class="thumb-pet">
                        <img class="img-fluid" src="/images/icon-1.png" />
                        <div class="content-pet">
                            <div class="name-pet">
                                <img src="/images/icon-def.png" />
                                <span class="name-content">Dracotara</span>
                            </div>
                            <div class="img-pet">
                                <img class="img-fluid" src="/images/heros/hero-2.png" />
                            </div>
                        </div>
                    </div>
                    <div class="thumb-pet">
                        <img class="img-fluid" src="/images/icon-1.png" />
                        <div class="content-pet">
                            <div class="name-pet">
                                <img src="/images/icon-def.png" />
                                <span class="name-content">Honnuwari</span>
                            </div>
                            <div class="img-pet">
                                <img class="img-fluid" src="/images/heros/hero-3.png" />
                            </div>
                        </div>
                    </div>
                    <div class="thumb-pet">
                        <img class="img-fluid" src="/images/icon-1.png" />
                        <div class="content-pet">
                            <div class="name-pet">
                                <img src="/images/icon-def.png" />
                                <span class="name-content">Cravatas</span>
                            </div>
                            <div class="img-pet">
                                <img class="img-fluid" src="/images/heros/hero-4.png" />
                            </div>
                        </div>
                    </div>
                    <!-- <div class="thumb-pet">
                        <img class="img-fluid" src="/images/icon-1.png" />
                        <div class="content-pet">
                            <div class="name-pet">
                                <img src="/images/icon-def.png" />
                                <span class="name-content">Venornion</span>
                            </div>
                            <div class="img-pet">
                                <img class="img-fluid" src="/images/pets/boss_5.png" />
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </section>

        <!-- <section class="section4-1" style="min-height: 0px; padding: 80px 0">
            <span class="title-3"></span>
            <div class="py-4">
                <div class="container text-center">
                    <div class="mb-4">
                        <span class="built-in built-network-heading">Built on &nbsp;</span>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-4">
                            <div class="built-network-item">
                                <a rel="noreferrer" href="https://solana.com/" target="_blank"><img
                                        src="/images/network/solana.webp" /></a>
                                <span class="built-in">Solana</span>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="built-network-item">
                                <a rel="noreferrer" href="https://binance.com/" target="_blank"><img
                                        src="/images/network/binance.png" /></a>
                                <span class="built-in">Binance Smart Chain</span>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="built-network-item">
                                <a rel="noreferrer" href="https://ethereum.org/" target="_blank"><img
                                        src="/images/network/ethereum.svg" /></a>
                                <span class="built-in">Ethereum</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> -->
        <!-- <section class="section4">
            <span class="title-3"></span>
            <div class="box-trailer">
                <img class="pet-rabbit" src="/images/animalvids/Tho.gif" />
                <img class="pet-rong1" src="/images/animalvids/Rong1.gif" />
                <iframe width="100%" height="100%" src="https://www.youtube.com/embed/iXDUwPABJ6E?si=B6hqkOBcALEuuX7a"
                    title="Unipet- Tựa game 3D nhập vai đầu tiên ứng dụng công nghệ Generative AI " frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>
            </div>
        </section> -->

        <!-- <section class="section5" id="teams">
            <span class="title-4"></span>
            <div class="box-team">
                <div class="slider slide-team">
                    <div class="item-team">
                        <div class="item-member">
                            <div class="avatar"><span><img class="img-fluid" src="/images/teams/thanh.jpg?v=2" /></span>
                            </div>
                            <span class="name">Nguyen Viet Thanh</span>
                            <span class="position">CEO</span>
                            <ul class="rs lst-social">
                                <li><a href="#" class="icon-fb"></a></li>
                                <li><a href="#" class="icon-in"></a></li>
                            </ul>
                        </div>
                        <div class="item-member">
                            <span class="avatar"><img src="/images/teams/vo.jpg?v=2" /></span>
                            <span class="name">Nguyen Van Vo</span>
                            <span class="position">CTO</span>
                            <ul class="rs lst-social">
                                <li><a href="#" class="icon-fb"></a></li>
                                <li><a href="#" class="icon-in"></a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="item-team">
                        <div class="item-member">
                            <span class="avatar"><img src="/images/teams/toan.jpg?v=2" /></span>
                            <span class="name">Vu Trong Toan</span>
                            <span class="position">Art Director</span>
                            <ul class="rs lst-social">
                                <li><a href="#" class="icon-fb"></a></li>
                                <li><a href="#" class="icon-in"></a></li>
                            </ul>
                        </div>
                        <div class="item-member">
                            <span class="avatar"><img src="/images/teams/hoang.jpg?v=2" /></span>
                            <span class="name">Truong Van Hoang</span>
                            <span class="position">Lead Game Developer</span>
                            <ul class="rs lst-social">
                                <li><a href="#" class="icon-fb"></a></li>
                                <li><a href="#" class="icon-in"></a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="item-team">
                        <div class="item-member">
                            <span class="avatar"><img src="/images/teams/anh.jpg?v=2" /></span>
                            <span class="name">Nguyen Tuan Anh</span>
                            <span class="position">Game Developer</span>
                            <ul class="rs lst-social">
                                <li><a href="#" class="icon-fb"></a></li>
                                <li><a href="#" class="icon-in"></a></li>
                            </ul>
                        </div>
                        <div class="item-member">
                            <span class="avatar"><img src="/images/teams/phuong.jpg?v=2" /></span>
                            <span class="name">Duong Thi Thu Phuong</span>
                            <span class="position">Lead Environment Artist</span>
                            <ul class="rs lst-social">
                                <li><a href="#" class="icon-fb"></a></li>
                                <li><a href="#" class="icon-in"></a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="item-team">
                        <div class="item-member">
                            <span class="avatar"><img src="/images/teams/thuan.jpg?v=2" /></span>
                            <span class="name">Truong Duy Thuan</span>
                            <span class="position">Lead 2D Artist</span>
                            <ul class="rs lst-social">
                                <li><a href="#" class="icon-fb"></a></li>
                                <li><a href="#" class="icon-in"></a></li>
                            </ul>
                        </div>
                        <div class="item-member">
                            <span class="avatar"><img src="/images/teams/thuy.jpg?v=2" /></span>
                            <span class="name">Nguyen Trong Thuy</span>
                            <span class="position">Lead 3D Artist</span>
                            <ul class="rs lst-social">
                                <li><a href="#" class="icon-fb"></a></li>
                                <li><a href="#" class="icon-in"></a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="item-team">
                        <div class="item-member">
                            <span class="avatar"><img src="/images/teams/tan.jpg?v=2" /></span>
                            <span class="name">Phung Van Tan</span>
                            <span class="position">Lead Animator</span>
                            <ul class="rs lst-social">
                                <li><a href="#" class="icon-fb"></a></li>
                                <li><a href="#" class="icon-in"></a></li>
                            </ul>
                        </div>
                        <div class="item-member">
                            <span class="avatar"><img src="/images/teams/lam.jpg?v=2" /></span>
                            <span class="name">Hoang Tieu Lam</span>
                            <span class="position">Community Manager</span>
                            <ul class="rs lst-social">
                                <li><a href="#" class="icon-fb"></a></li>
                                <li><a href="#" class="icon-in"></a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="item-team">
                        <div class="item-member">
                            <span class="avatar"><img src="/images/teams/hong.jpg?v=2" /></span>
                            <span class="name">Nguyen Thi Anh Hong</span>
                            <span class="position">Concept Artist</span>
                            <ul class="rs lst-social">
                                <li><a href="#" class="icon-fb"></a></li>
                                <li><a href="#" class="icon-in"></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>


        </section> -->
        <!-- <section class="section6" id="roadmap">
            <span class="title-5"></span>
            <div class="fixCen">
                <div class="top-date">2022-2023</div>
                <div class="list-progress">
                    <img class="pet-gau" src="/images/animalvids/Gau.gif" />
                    <img class="pet-cho" src="/images/animalvids/Cho.gif" />
                    <div class="item-progress active">
                        <span class="icon-level"></span>
                        <div class="date">Q3-2022</div>
                        <ul class="rs">
                            <li>Alpha Test</li>
                            <li>IDO</li>
                            <li>Open Marketplace</li>
                        </ul>
                    </div>
                    <div class="item-progress">
                        <span class="icon-level"></span>
                        <div class="date">Q4-2022</div>
                        <ul class="rs">
                            <li>Release Game</li>
                            <li>NFT Trade system</li>
                            <li>New monsters</li>
                        </ul>
                    </div>
                    <div class="item-progress">
                        <span class="icon-level"></span>
                        <div class="date">Q1-2023</div>
                        <ul class="rs">
                            <li>New NFT customized pet</li>
                            <li>Staking system</li>
                            <li>PvP function</li>
                        </ul>
                    </div>
                    <div class="item-progress">
                        <span class="icon-level"></span>
                        <div class="date">Q2-2023</div>
                        <ul class="rs">
                            <li>AR function</li>
                            <li>Ultimate skin function</li>
                            <li>Open world development - Metaverse</li>
                        </ul>
                    </div>
                </div>
            </div>

        </section> -->
        <section class="section8">
            <span class="title-8"></span>
            <div class="box-trailer">
                <img class="pet-rabbit" src="/images/pets/boss_5.png" />
                <img class="pet-rong1" src="/images/heros/hero-4.png" />
                <iframe width="100%" height="100%" src="https://www.youtube.com/embed/G_lsCksLJ2U?si=neWqInFDSxMbqLCe"
                    title="YouTube video player" frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>
            </div>
        </section> 
        <!-- <footer-component /> -->

    </div>
</template>
<script>
import IndexNavbar from "@/components/Navbars/IndexNavbar.vue";
// import FooterComponent from "@/components/Footers/Footer.vue";

import patternVue from "@/assets/img/pattern_vue.png";
import componentBtn from "@/assets/img/component-btn.png";
import componentProfileCard from "@/assets/img/component-profile-card.png";
import componentInfoCard from "@/assets/img/component-info-card.png";
import componentInfo2 from "@/assets/img/component-info-2.png";
import componentMenu from "@/assets/img/component-menu.png";
import componentBtnPink from "@/assets/img/component-btn-pink.png";
import documentation from "@/assets/img/documentation.png";
import login from "@/assets/img/login.jpg";
import profile from "@/assets/img/profile.jpg";
import landing from "@/assets/img/landing.jpg";
//init wallet 
// import SolanaWallets from "solana-wallets-vue";
// import "solana-wallets-vue/styles.css";
// import {
//   PhantomWalletAdapter,
//   SlopeWalletAdapter,
//   SolflareWalletAdapter,
// } from "@solana/wallet-adapter-wallets";

// const walletOptions = {
//   wallets: [
//     new PhantomWalletAdapter(),
//     new SlopeWalletAdapter(),
//     new SolflareWalletAdapter({ network: WalletAdapterNetwork.Devnet }),
//   ],
//   autoConnect: true,
// };
// initWallet(walletOptions);
//end init wallet


// import getProvider from "../../components/Web3/getProvider.ts";
// const provider =  getProvider(); // see "Detecting the Provider"

// try {
//     const resp = await provider.connect();
//     console.log(resp.publicKey.toString());
//     // 26qv4GCcx98RihuK3c4T6ozB3J7L6VwCuFVc7Ta2A3Uo 
// } catch (err) {
//     // { code: 4001, message: 'User rejected the request.' }
// }
// provider.on("connect", () => console.log("connected!"));
// console.log(provider.publicKey.toString());
// // 26qv4GCcx98RihuK3c4T6ozB3J7L6VwCuFVc7Ta2A3Uo 
// console.log(provider.isConnected);
// // true
// provider.on('accountChanged', (publicKey) => {
//     if (publicKey) {
//         // Set new public key and continue as usual
//         console.log(`Switched to account ${publicKey.toBase58()}`);
//     } 
// });


// // ----------- send transsaction
// import signAndSendTransaction from '../../components/Web3/signAndSendTransaction.ts';

// console.log('signAndSendTransaction',signAndSendTransaction);
//end send transaction
export default {
    data() {
        return {
            patternVue,
            componentBtn,
            componentProfileCard,
            componentInfoCard,
            componentInfo2,
            componentMenu,
            componentBtnPink,
            documentation,
            login,
            profile,
            landing,
        };
    },
    components: {
        IndexNavbar,
        // FooterComponent,   
    },
};
</script>
