
<template>
         <header class="header-top">
            <button class="menu-trigger" id="menu01">
                <span></span>
                <span></span>
                <span></span>
            </button>
            <h1 class="rs">
                <a href="#" class="logo animated fadeInDown"></a>
            </h1>
            <div class="list-menu" id="mainmenu">
                <ul class="rs menu-left">
                    <li><a href="/">HOME</a></li>
                    <li><a href="#teams">TEAM</a></li>
                    <li><a href="/whitepaper">WHITEPAPER</a></li>
                </ul>

                <ul class="rs menu-right">
                    <li><a href="#roadmap">ROADMAP</a></li>
                    <li><router-link to="/market">MARKET</router-link></li>
                    <li>
                        <ul style="display: block;">
                            <li>
                               
                                <a href="#" v-if="publicKey !=''"  v-on:click="signAndSendTransaction()" class="btn-connect">Play now</a>
                             
                                <a href="#" v-else  v-on:click="connectWeb3()" class="btn-connect">Play now</a>
                               
                            </li>
                            <li v-if="publicKey !=''"><div style="display: block;color:white;text-align: center;">ID: {{ publicKey }}</div></li>
                        </ul>
                       
                    </li>
                   
                </ul>
              
            </div>
           
        </header>
      
</template>   
<script>
function truncate(str, n){
    if(str){
        return (str.length > n) ? str.slice(0, n-1) + '...' : str;
    }else{
        return "";
    }
}
import signAndSendTransaction from '../../components/Web3/signAndSendTransaction.ts';
import connectWeb3 from '../../components/Web3/connectWeb3.ts';

console.log('signAndSendTransaction',signAndSendTransaction);
var publicKey = truncate(localStorage.getItem('publicKey'),15);
console.log('publicKey',publicKey);

export default {
  data() {
    return {
        publicKey
    };
  },
  methods: {
    signAndSendTransaction: signAndSendTransaction,
    connectWeb3:connectWeb3,
  
  },
};

</script>
