<template>
    <section class="section7" id="section7">
            <span class="title-6"></span>
            <div class="box-partner">
                <a rel="noreferrer" href="https://www.animocabrands.com/" target="_blank"><img
                        src="images/partners/amonica.png" style="max-height: 120px;" /></a>
                <!-- <a rel="noreferrer" href="https://gamefi.org/" target="_blank"><img
                        src="images/partners/gamefi.svg" /></a> -->
                <a rel="noreferrer" href="https://redkite.polkafoundry.com/#/" target="_blank"><img
                        src="images/partners/red_kite.webp" style="max-height: 160px;" /></a>
                <!-- <a rel="noreferrer" href="https://redkite.polkafoundry.com/#/" target="_blank"><img
                        src="images/partners/p1.png" style="max-height: 120px;" /></a> -->
                <a rel="noreferrer" href="https://redkite.polkafoundry.com/#/" target="_blank"><img
                        src="images/partners/p2.png" style="max-height: 120px;" /></a>
            </div>
            <footer class="footer">
                <div class="inner-footer">
                    <!-- <img class="pet-rong2" src="images/animalvids/Rong2.gif" /> -->
                    <div class="item">
                        <h4 class="rs">OVERVIEW</h4>
                        <ul class="rs">
                            <!-- <li><a href="#">How to earn?</a></li> -->
                            <li><a href="#teams">Team</a></li>
                            <li><a href="#section3">NFTs</a></li>
                            <!-- <li><a href="#">Shop</a></li> -->
                            <li><a href="#section7">Partners</a></li>
                            <li><a href="#">About the game</a></li>
                        </ul>
                    </div>
                    <div class="item">
                        <h4 class="rs">TOKEN</h4>
                        <ul class="rs">
                            <!-- <li><a href="#">Staking</a></li> -->
                            <li><a href="#">Tokenomics</a></li>
                            <!-- <li><a href="#">Buy on DEX</a></li> -->
                        </ul>
                    </div>
                    <div class="item">
                        <h4 class="rs">LEGAL</h4>
                        <ul class="rs">
                            <li><a href="#roadmap">Roadmap</a></li>
                            <li><a href="#">Whitepaper</a></li>
                        </ul>
                    </div>
                </div>
                <div class="info-footer">
                    <!-- <a href="#" class="logo-partner"><img class="img-fluid" src="images/logo-2.png" /></a> -->
                    <div class="info-our">
                        <!-- <h4 class="rs">JOIN OUR COMMUNITY</h4>
                        <ul class="rs">
                            <li><a href="#" class="spr icon-fb"></a></li>
                            <li><a href="#" class="spr icon-game"></a></li>
                            <li><a href="#" class="spr icon-telegrame"></a></li>
                            <li><a href="#" class="spr icon-youtube"></a></li>
                            <li><a href="#" class="spr icon-tiktok"></a></li>
                        </ul> -->
                    </div>
                </div>
            </footer>
        </section>

</template>
<!-- <script>
export default {
  data() {
    return {
      date: new Date().getFullYear(),
    };
  },
};
</script> -->
